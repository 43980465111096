$(function() {
	$('#page_nav .page_nav--submenu + .page_nav--submenu_arrow').on('click', function() {
		var siblings = $(this).siblings('.page_nav--submenu');

		$('.page_nav--submenu, .page_nav--submenu_list').not(siblings).slideUp(300).parent().removeClass('open');
		siblings.slideToggle(300);
		$(this).parent().toggleClass('open');
		return false;
	});

	$('#page_nav .page_nav--submenu_list + .page_nav--submenu_arrow').on('click', function() {
		var siblings = $(this).siblings('.page_nav--submenu_list');

		$('.page_nav--submenu_list').not(siblings).slideUp(300).parent().removeClass('open');
		siblings.slideToggle(300);
		$(this).parent().toggleClass('open');
		return false;
	});
});