$(function () {
  $(".basket_layer").on("click", function () {
    $(this).animate({ right: -500 }, 300, function () {
      $(this).remove();
    });
    return false;
  });
  $(".basket_layer").each(function () {
    var layer = $(this);
    setTimeout(function () {
      layer.animate({ right: -500 }, 300, function () {
        $(this).remove();
      });
    }, 5000);
  });
  $(".basket_layer > a").on("click", function (e) {
    e.stopPropagation();
  });

  $(".info_panel .info_panel--handler").on("click", function () {
    $(".info_panel").toggleClass("open");
  });

  $("a[data-fancybox]").fancybox();

  $(".product_pictures--thumbs").slick({
    slidesToShow: 1,
    slidesToScroll: 1,
    fade: true,
    centerMode: false,
    rows: 0,
    asNavFor: ".product_pictures--nav",
  });
  $(".product_pictures--nav").slick({
    slidesToShow: 4,
    slidesToScroll: 1,
    infinite: false,
    centerMode: false,
    asNavFor: ".product_pictures--thumbs",
    // fade: true,
    focusOnSelect: true,
    arrows: true,
    vertical: true,
    rows: 0,
  });

  $(".product_pictures--similar_list").slick({
    slidesToShow: 3,
    slidesToScroll: 1,
    infinite: false,
    arrows: true,
    rows: 0,
  });
});
