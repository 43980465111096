const showBasketLayer = (
  product,
  text = "Added to basket",
  url = "/basket"
) => {
  const basketLayer = document.createElement("div");
  basketLayer.classList.add("basketLayer", "hidden");

  const imageWrapper = document.createElement("div");
  imageWrapper.classList.add("basketLayer-image");
  const image = document.createElement("img");
  image.src = product.medium_image_path;
  imageWrapper.appendChild(image);
  basketLayer.appendChild(imageWrapper);
  const textWrapper = document.createElement("div");
  textWrapper.classList.add("basketLayer-text");
  textWrapper.innerHTML = text;
  basketLayer.appendChild(textWrapper);
  const link = document.createElement("a");
  link.href = url;
  basketLayer.appendChild(link);
  // append new div to body
  document.body.appendChild(basketLayer);
  setTimeout(() => {
    basketLayer.classList.remove("hidden");
  }, 100);

  const basketLayerJQ = $(basketLayer);
  // add events
  link.addEventListener("click", (e) => {
    e.stopPropagation();
  });

  setTimeout(() => {
    basketLayerJQ.animate({ right: -500 }, 250, () => {
      basketLayerJQ.remove();
    });
  }, 5000);
  return false;
};
window.showBasketLayer = showBasketLayer;

const refreshBasketSize = () => {
  $.get("/api/basket", (response) => {
    const items = response.basket.items_count;
    $(".page_header--basket .page_header--basket_text").text(items);
  });
};

window.refreshBasketSize = refreshBasketSize;

$(function () {
  $(".btn-basket[data-method=post]").on("click", function () {
    const productId = $(this).data("productId");

    $.post(`/api/basket/${productId}/create`, { quantity: 1 }, (response) => {
      showBasketLayer(response.basket_item.product);

      gtag("event", "add_to_cart", {
        items: [
          {
            item_id: response.basket_item.product.id,
            item_name: response.basket_item.product.name,
            item_brand: response.basket_item.product.producer_name,
            item_category: response.basket_item.product.category_name,
            price: Number(response.basket_item.product.current_price),
          },
        ],
      });
      refreshBasketSize();
    });
    return false;
  });
});
