window.addToCompareList = (url) => {
  $.post(
    url,
    (response) => {
      if (response.status == "success") {
        $("#compare_box").replaceWith(response.html);
      }
      return false;
    },
    "json"
  );
};

$(function () {
  // compare page
  if ($("#page_compare").length > 0) {
    var onCompareScroll = function () {
      var scrollTop = $(window).scrollTop();
      if (scrollTop > $("#page_compare .compare_list").position().top) {
        $("#page_compare").addClass("scrolled");
      } else {
        $("#page_compare").removeClass("scrolled");
      }
    };
    $(window).on("scroll", onCompareScroll);
    onCompareScroll();
  }

  $(document).on("click", ".compare-link", function () {
    const url = $(this).prop("href");
    addToCompareList(url);

    return false;
  });

  $(document).on("click", ".compare_box--item_destroy", function () {
    var url = $(this).prop("href");
    addToCompareList(url);

    return false;
  });

  $(document).on("click", "#compare_box--close", function () {
    var url = $(this).prop("href");

    $.post(
      url,
      function (response) {
        if (response.status == "success") {
          $("#compare_box").replaceWith(response.html);
        }
        return false;
      },
      "json"
    );
    return false;
  });

  $(document).on(
    "click",
    "#compare_box .compare_box--layout_actions a",
    function () {
      var url = $(this).attr("href");

      var comparedProducts = [];
      $("#compare_box .compare_box--item").each(function () {
        comparedProducts.push($(this).data("productId"));
      });
      var productIds = comparedProducts.join(",");
      url += "?products=" + productIds;
      $(location).attr("href", url);
      return false;
    }
  );
});
